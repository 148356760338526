import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ButtonsBarService, IUser, LoginDialogComponent, UserUI } from '@ms/core';
import { PrincipalService } from '@ms/core/auth/principal.service';
import { DialogService } from '@ms/core/components/dialog/service/dialog.service';
import { LoginService } from '@ms/core/components/login/login.service';
import { CombineSubscriptions, DestroySubscribers } from '@ms/core/decorator/ngx-destroy-subscribers';
import { EventManager } from '@ms/core/service/event-manager.service';
import { Observable, Unsubscribable, fromEvent } from 'rxjs';

import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { SpinnerOverlayService } from '@ms/core/components/login/spinner/spinner-overlay/spinner-overlay.service';
import { SessionStoreService } from '@ms/core/components/login/state/session.store';
import { navigationTitle } from 'app/layout/layout.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { openPasswordDialog } from './main/shared/dialog-util';
import { StartupService } from './system/startup.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroySubscribers()
export class AppComponent implements OnInit, OnDestroy {
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    @CombineSubscriptions()
    subscriber: Unsubscribable;
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _platform: Platform,
        private principal: PrincipalService,
        public startupService: StartupService,
        private _dialogService: DialogService,
        private eventManager: EventManager,
        private loginService: LoginService,
        private buttonBarService: ButtonsBarService,
        private router: Router,
        private cookieService: CookieService,
        private location: LocationStrategy,
        public _progressService: FuseProgressBarService,
        private _sessionStore: SessionStoreService,
        private overlayService: SpinnerOverlayService
    ) {
        const rawUserData = sessionStorage.getItem('currentUser');
        if (rawUserData && !this.startupService.isAuthenticated()) {
            //debugger;
            const account = JSON.parse(rawUserData) as IUser;
            console.log('Stored Account', account);
            this.principal.refreshedApp = true;
            this.principal.initUserAuth(true, true, account);
            this.loginService.afterLogin(account);
        } else {
            this.principal.refreshedApp = false;
        }
        this.loginService.logoutStarted = false;

        //console.log('INIT Logged', this.principal.authenticatedUser);
        this.subscriber = this.eventManager.subscribe('user-changed', content => {
            console.log('USER CHANGED', content);
            if (this.startupService.isAuthenticated() && this.startupService.user()?.ui) {
                this.startupService.userUI.set(this.startupService.user().ui);
                if (
                    this.startupService.userUI() != UserUI.NONE &&
                    this.startupService.contentContainer() == undefined &&
                    !this.startupService.layoutLoading()
                ) {
                    console.log('Content init');
                    this.startupService.layoutLoading.set(true);
                    this.lazyContent();
                }
                setTimeout(() => {
                    this.eventManager.broadcast({
                        name: 'uaa-url',
                        content: this.startupService.uaaUrl,
                    });
                }, 2000);
            }
        });

        this.subscriber = this.eventManager.subscribe('user-logged-out', () => {
            this.startupService.contentContainer.set(undefined);
            this.startupService.layoutLoading.set(false);
            this.startupService.isAuthenticated.set(false);
            this.startupService.userUI.set(UserUI.NONE);
            this.startupService.init(false);
            this.loginService.clearStorage();
            if (!this.startupService.showLoginDialog()) {
                this.loginDialog();
            }
        });
        //startupService.init(true);
        this.startupService.init(false);
        //compile error
        this._fuseSplashScreenService.empty();

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        history.pushState(null, null, window.location.href);
        // check if back or forward button is pressed.
        this.location.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });
    }

    loadUserDataFromStore() {
        if (!this.startupService.isAuthenticated() && !this.startupService.user()?.id) {
            const rawUserData = sessionStorage.getItem('currentUser');
            if (rawUserData) {
                const account = JSON.parse(rawUserData) as IUser;
                console.log('Stored Account', account);
                this.principal.refreshedApp = true;
                this.principal.initUserAuth(true, true, account);
                this.loginService.afterLogin(account);
            } else {
                this.startupService.reloadingAccount.set(true);
                this.principal
                    .identity()
                    .then(account => {
                        this.loginService.afterLogin(account);
                    })
                    .catch(() => {
                        this.cookieService.delete('login');
                    })
                    .finally(() => {
                        this.startupService.reloadingAccount.set(false);
                    });
            }
        }
    }

    async lazyContent() {
        if (this.startupService.userUI() == UserUI.DEFAULT) {
            console.log('CONTENT-INIT');
            import('app/layout/vertical/layout-1/layout-1.component')
                .then(comp => {
                    this.startupService.contentContainer.set(comp.VerticalLayout1Component);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.eventManager.broadcast({
                            name: 'after-login-event',
                            account: this.startupService.user(),
                            refreshSchoolList: this.startupService.user().activeSchool ? false : true,
                        });
                    });
                });
        } else if (this.startupService.userUI() == UserUI.STUDENT) {
            console.log('CONTENT-INIT');
            import('app/layout/horizontal/student/student-layout.component')
                .then(comp => {
                    this.startupService.contentContainer.set(comp.StudentLayoutComponent);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.eventManager.broadcast({
                            name: 'after-login-event',
                            account: this.startupService.user(),
                            refreshSchoolList: this.startupService.user().activeSchool ? false : true,
                        });
                    });
                });
        } else if (this.startupService.userUI() == UserUI.TEACHER) {
            console.log('CONTENT-INIT');
            import('app/layout/horizontal/teacher/teacher-layout.component')
                .then(comp => {
                    this.startupService.contentContainer.set(comp.TeacherLayoutComponent);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.eventManager.broadcast({
                            name: 'after-login-event',
                            account: this.startupService.user(),
                            refreshSchoolList: this.startupService.user().activeSchool ? false : true,
                        });
                    });
                });
        } else if (this.startupService.userUI() == UserUI.PARENT) {
            console.log('CONTENT-INIT');
            import('app/layout/horizontal/parent/parent-layout.component')
                .then(comp => {
                    this.startupService.contentContainer.set(comp.ParentLayoutComponent);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.eventManager.broadcast({
                            name: 'after-login-event',
                            account: this.startupService.user(),
                            refreshSchoolList: this.startupService.user().activeSchool ? false : true,
                        });
                    });
                });
        }
    }
    /**
     * On init
     */
    ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
            if (event instanceof NavigationStart) {
                this._progressService.show();
                const browserRefresh = !this.router.navigated;
                const refreshPage = this.cookieService.check('login');
                if (
                    !this.startupService.reloadingAccount() &&
                    refreshPage &&
                    browserRefresh &&
                    this.startupService.isAuthenticated()
                ) {
                    console.log('REFRESH PAGE');
                    this.loadUserDataFromStore();
                }
                //this.buttonBarService.clear();
            } else if (event instanceof NavigationEnd) {
                this._progressService.hide();
            }
        });

        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriber = this.onlineEvent.subscribe(() => {
            this._dialogService.close('connection-lost');
        });
        this.subscriber = this.offlineEvent.subscribe(() => {
            this._dialogService.info('Browser is offline.Please check your internet connection', {
                id: 'connection-lost',
                dismissible: false,
                autoClose: false,
            });
        });
        console.log('App Init');
        navigationTitle.next({
            title: 'NAV.DASHBOARD',
            returnBack: false,
            backShowOnMobile: false,
        });
        this.buttonBarService.addMobile(null);

        this.subscriber = this.eventManager.subscribe('show-login', () => {
            this.startupService.contentContainer.set(undefined);
            console.log('CURRENT_URL', this.router.url, this.startupService.isAuthenticated());
            if (this.router.url != '') {
                this.router.navigateByUrl('');
            }
            if (this.startupService.isAuthenticated()) {
                this.loginService.logout();
            }
            //if (!this.startupService.isAuthenticated()) {
            this.loginDialog();
            //}
        });

        // Subscribe to config changes
        this.subscriber = this._fuseConfigService.config.subscribe(config => {
            this.startupService.fuseConfig.set(config);

            if (this.startupService.fuseConfig().layout.width == 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }
        });

        this.subscriber = this.eventManager.subscribe('change-password', () => {
            this.changePasswordDialog();
        });

        //this.printpath('', this.router.config);
        // if (!environment.production) {
        // const rawUserData = sessionStorage.getItem('currentUser');
        if (!this.principal.refreshedApp && !this.startupService.isAuthenticated()) {
            console.log('Route Login Page');
            this.router.navigateByUrl('', { replaceUrl: true }).finally(() => {
                this.loginDialog();
            });
            return;
        }
    }

    printpath(parent: string, config: Route[]) {
        for (let i = 0; i < config.length; i++) {
            const route = config[i];
            //console.log(parent + '/' + route.path);
            if (route.children) {
                const currentPath = route.path ? `${parent}/${route.path}` : parent;
                this.printpath(currentPath, route.children);
            }
        }
    }

    loginDialog() {
        if (this.startupService.showLoginDialog()) {
            return;
        }
        this.startupService.showLoginDialog.set(true);
        console.log('SHOW-LOGIN');
        this._dialogService.closeAll();
        this._dialogService.open(
            LoginDialogComponent,
            {
                width: '400px',
                disableClose: true,
                autoFocus: true,
                hasBackdrop: false,
                panelClass: 'dialog-custom-shadow',
                backdropClass: 'cdk-overlay-transparent-backdrop',
            },
            () => {
                //console.log('SHOW-LOGIN');
                this.startupService.showLoginDialog.set(false);
                setTimeout(() => {
                    this.overlayService.hide();
                });
            }
        );
    }

    @HostListener('window:pagehide', ['$event'])
    async ngOnDestroy() {
        const pageReloaded = window.performance
            .getEntriesByType('navigation')
            .map(nav => (nav as any).type)
            .includes('reload');
        if (!pageReloaded) {
            if (environment.production && this.startupService.isAuthenticated()) {
                console.log('PAGE-RELOAD');
                this.loginService.logout();
                this._sessionStore.clear();
                sessionStorage.removeItem('currentUser');
            }
        }
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    changePasswordDialog() {
        this._dialogService.closeAll();
        this.startupService.showLoginDialog.set(false);
        openPasswordDialog(
            {
                reLogin: true,
            },
            () => {
                this.loginDialog();
            }
        );
    }
}
