import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeKk from '@angular/common/locales/kk';
import localeRu from '@angular/common/locales/ru';
import localeTr from '@angular/common/locales/tr';
import {
    APP_INITIALIZER,
    ApplicationRef,
    CUSTOM_ELEMENTS_SCHEMA,
    DoBootstrap,
    ErrorHandler,
    Injector,
    NgModule,
    Optional,
    Sanitizer,
    SkipSelf,
} from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
    BrowserModule,
    DomSanitizer,
    REMOVE_STYLES_ON_COMPONENT_DESTROY,
    ɵDomSanitizerImpl,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FuseModule } from '@fuse/fuse.module';
import { Translations, WebpackTranslateLoader } from '@fuse/utils/translate';
import {
    CustomMissingTranslationHandler,
    DialogService,
    GatewayService,
    GlobalErrorHandler,
    GlobalHttpInterceptor,
    LoginDialogComponent,
} from '@ms/core';
import { throwIfAlreadyLoaded } from '@ms/core/shared/module-import.guard';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { CustomErrorStateMatcher } from '@ms/core/util/validators';
import { DYNAMIC_MATCHER_PROVIDERS } from '@ng-dynamic-forms/core';
import { provideHotToastConfig } from '@ngxpert/hot-toast/hot-toast.provide';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { fuseConfig } from './fuse-config';
import { MustHaveDefaultSchoolGuard } from './main/school-class/school-class-route.guard';
import { PaginatorI18n } from './paginator-i18n';
import { initIcons } from './vendor';

export const BROWSER_SANITIZATION_PROVIDERS: Array<any> = [
    { provide: Sanitizer, useExisting: DomSanitizer },
    { provide: DomSanitizer, useClass: ɵDomSanitizerImpl },
];

// eslint-disable-next-line func-style
export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
    return () => overlay.scrollStrategies.close();
}

export const provideConfig = (gatewayService: GatewayService) => {
    return firstValueFrom(gatewayService.socialLoginConfig);
};

export const initApp = (gatewayService: GatewayService) => {
    return async () => {
        return await firstValueFrom(gatewayService.serverInfo()).then(
            result => {
                if (result) {
                    environment.serverInfo = result;
                    environment.serverInfo.userTimeZone = new Date().getTimezoneOffset();
                    console.log('SERVER-CONFIG', result);
                    console.log('ENVIRONMENT', environment);
                    gatewayService.socialLoginConfigInit();
                }
            },
            error => {
                environment.serverInfo = undefined;
                console.log('ERROR:', error);
            }
        );
    };
};

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FuseModule.forRoot(fuseConfig),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
        }),
        LoginDialogComponent,
        SocialLoginModule,
        MatSnackBarModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [GatewayService, DialogService],
            multi: true,
        },
        Translations,
        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService),
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptor,
            multi: true,
            deps: [Injector],
        },
        MustHaveDefaultSchoolGuard,
        BROWSER_SANITIZATION_PROVIDERS,
        { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
        { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { separatorKeyCodes: [ENTER, COMMA] } },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        //TODO: Datepicker move
        { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: true },
        provideHotToastConfig(),
        {
            provide: 'SocialAuthServiceConfig',
            useFactory: provideConfig,
            deps: [GatewayService],
        },
        ...DYNAMIC_MATCHER_PROVIDERS,
        { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true },
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule implements DoBootstrap {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AppModule,
        private faConfig: FaConfig,
        private library: FaIconLibrary,
        injector: Injector
    ) {
        environment.injector = injector;
        registerLocaleData(localeRu, 'ru');
        registerLocaleData(localeKk, 'kk');
        registerLocaleData(localeDe, 'de');
        registerLocaleData(localeTr, 'tr');
        registerLocaleData(localeFr, 'fr');

        this.faConfig.defaultPrefix = 'fal';
        this.faConfig.fixedWidth = true;
        initIcons(this.library);
        dom.watch();
        throwIfAlreadyLoaded(parentModule, 'AppModule');
    }
    // eslint-disable-next-line class-methods-use-this
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(AppComponent);
    }
}
