import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl {
    constructor(private readonly translate: TranslateService) {
        super();
        this.getPaginatorIntl();
        this.translate.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
            this.getPaginatorIntl();
        });
    }

    getPaginatorIntl() {
        this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE_LABEL');
        this.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE_LABEL');
        this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE_LABEL');
        this.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE_LABEL');
        this.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE_LABEL');
        this.getRangeLabel = this.getRangeLabelF.bind(this);
        this.changes.next();
    }

    getRangeLabelF(page: number, pageSize: number, length: number): string {
        if (length == 0 || pageSize == 0) {
            return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
    }
}
