import { NgModule, Optional, SkipSelf, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrincipalService } from '@ms/core';
import { UserRouteAccessService } from '@ms/core/auth/user-route-access-service';
import { throwIfAlreadyLoaded } from '@ms/core/shared/module-import.guard';
import { environment } from 'environments/environment';
import { ImportedDocumentType } from './main/imported-documents/imported-documents.model';
import { MustHaveDefaultSchoolGuard } from './main/school-class/school-class-route.guard';
import { AuthGuard } from './system/auth.guard';

export const isHeadTeacher = () => {
    const principal = inject(PrincipalService);
    if (principal.isAuthenticated && principal.authenticatedUser.ui == 'TEACHER') {
        console.log('HEAD-TEACHER');
        return principal.hasAuthority('ROLE_HEAD_TEACHER');
    }
    return false;
};

export const isIncidentManager = () => {
    const principal = inject(PrincipalService);
    if (principal.isAuthenticated && principal.authenticatedUser.ui == 'TEACHER') {
        console.log('HEAD-TEACHER');
        return principal.hasAuthority('ROLE_INCIDENT_MANAGER');
    }
    return false;
};

// import { PreloadSelectedModules } from './custom-preloader';

const routes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [AuthGuard],
    },
    {
        path: 'release-notes',
        loadChildren: () =>
            import('app/layout/components/content/release-notes/release-notes.module').then(m => m.ReleaseNotesModule),
    },
    {
        path: 'school',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/schools/school-list/schools.component').then(m => m.SchoolsComponent),
                canActivate: [AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439027', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                canActivate: [UserRouteAccessService],
                loadComponent: () =>
                    import('app/main/schools/school-list/school-form/school-form.component').then(
                        m => m.SchoolFormComponent
                    ),
            },
            {
                path: 'info/:id',
                canActivate: [UserRouteAccessService],
                loadComponent: () =>
                    import('app/main/schools/school-list/school-form/school-form.component').then(
                        m => m.SchoolFormComponent
                    ),
            },
            {
                path: 'new/:tenantId',
                canActivate: [UserRouteAccessService],
                loadComponent: () =>
                    import('app/main/schools/school-list/school-form/school-form.component').then(
                        m => m.SchoolFormComponent
                    ),
            },
            {
                path: 'users',
                loadComponent: () =>
                    import('app/main/school-user/school-user.component').then(m => m.SchoolUserComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439034', // navigation-ams.json ID = /school/users
                },
            },
        ],
    },
    {
        path: 'subject-categories/list',
        loadComponent: () =>
            import('app/main/subject-categories/subject-categories.component').then(m => m.SubjectCategoriesComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439017', // navigation-ams.json ID
        },
    },
    {
        path: 'fee-types/list',
        loadComponent: () => import('app/main/fee-type/fee-type.component').then(m => m.FeeTypeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439019', // navigation-ams.json ID
        },
    },
    {
        path: 'discounts/list',
        loadComponent: () => import('app/main/discount/discount.component').then(m => m.DiscountComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439021', // navigation-ams.json ID
        },
    },
    {
        path: 'custom-parameters/all',
        loadComponent: () =>
            import('app/main/custom-parameters/custom-parameters.component').then(m => m.CustomParametersComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '609f1f77bcf86cd799439019', // navigation-ams.json ID
        },
    },
    {
        path: 'incident-parameters',
        redirectTo: () => {
            return '/custom-parameters/incident';
        },
        data: {
            permissionId: '507f1f77bcf86cd799439195', // navigation-ams.json ID
        },
    },
    {
        path: 'medical-parameters',
        redirectTo: () => {
            return '/custom-parameters/medical';
        },
        data: {
            permissionId: '507f2f77bcf86cd799439146', // navigation-ams.json ID
        },
    },
    {
        path: 'student-program-parameters',
        redirectTo: () => {
            return '/custom-parameters/student-program';
        },
        data: {
            permissionId: '507f1f77bcf86cd799439009', // navigation-ams.json ID
        },
    },
    {
        path: 'custom-parameters/:source',
        loadComponent: () =>
            import('app/main/custom-parameters/custom-parameters.component').then(m => m.CustomParametersComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
    },

    {
        path: 'attendance-codes',
        loadComponent: () =>
            import('app/main/attendance-codes/attendance-code.component').then(m => m.AttendanceCodeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '609f1f77bcf86cd799439019', // navigation-ams.json ID
        },
    },
    {
        path: 'data-permission',
        loadComponent: () =>
            import('app/main/data-permission/data-permission.component').then(m => m.DataPermissionComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '609f1f77bcf86cd799439021', // navigation-ams.json ID
        },
    },
    {
        path: 'document-types',
        loadComponent: () =>
            import('app/main/document-types/document-type.component').then(m => m.AttachmentTypeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439023', // navigation-ams.json ID
        },
    },
    {
        path: 'subjects',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/subjects/school-subjects.component').then(m => m.SchoolSubjectsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439018', // navigation-ams.json ID
                },
            },
            {
                path: 'curriculums/subject/:subjectId',
                loadComponent: () =>
                    import('app/main/shared/subjects/curriculum/curriculum.component').then(m => m.CurriculumComponent),
                data: {
                    permissionId: '507f1f77bcf86cd799439018', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'academic-periods/:schoolId',
        loadComponent: () =>
            import('app/main/academic-period-tree/academic-period.component').then(m => m.AcademicPeriodComponent),
    },
    {
        path: 'grade-level',
        loadComponent: () => import('app/main/grade-levels/grade-levels.component').then(m => m.GradeLevelsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439022', // navigation-ams.json ID
        },
    },
    {
        path: 'involvement-items/list',
        loadComponent: () =>
            import('app/main/involvement-item/involvement-item.component').then(m => m.InvolvementItemComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439168', // navigation-ams.json ID
        },
    },
    {
        path: 'student/list',
        loadComponent: () => import('app/main/students/student-list/students.component').then(m => m.StudentsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439036', // navigation-ams.json ID
        },
    },
    {
        path: 'student/info',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/students/student-list/student-form/student-form.component').then(
                m => m.StudentFormComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799439036', // navigation-ams.json ID
            //fullPage: true,
        },
    },
    {
        path: 'student/info/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/students/student-list/student-form/student-form.component').then(
                m => m.StudentFormComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799439036', // navigation-ams.json ID
            //fullPage: true,
        },
    },
    {
        path: 'student/class/transfer',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/students/transfer/class-students.component').then(m => m.ClassStudentsComponent),
        data: {
            permissionId: '5c989bf4f3f0a11907461409', // navigation-ams.json ID
        },
    },
    {
        path: 'student/class/transfer/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/students/transfer/class-students.component').then(m => m.ClassStudentsComponent),
        data: {
            permissionId: '5c989bf4f3f0a11907461409', // navigation-ams.json ID
        },
    },
    {
        path: 'student/class/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/student-list/students-list-class.component').then(m => m.StudentsListClassComponent),
        data: {
            type: 'class-assign',
        },
    },
    {
        path: 'representative-account',
        loadComponent: () =>
            import('app/main/students/representative-account/representative-account.component').then(
                m => m.RepresentativeAccountComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439036', // navigation-ams.json ID
        },
    },
    {
        path: 'school-class',
        loadComponent: () => import('app/main/school-class/school-class.component').then(m => m.SchoolClassComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439029', // navigation-ams.json ID
        },
        children: [
            {
                path: ':schoolId',
                loadComponent: () =>
                    import('app/main/school-class/school-class.component').then(m => m.SchoolClassComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
            },
            {
                path: ':schoolId/:academicYearId',
                loadComponent: () =>
                    import('app/main/school-class/school-class.component').then(m => m.SchoolClassComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
            },
        ],
    },
    {
        path: 'salary-constants',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('app/main/salary-constants/salary-constants.component').then(
                        m => m.SalaryConstantsComponent
                    ),

                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439051', // navigation-ams.json ID
                },
            },
            {
                path: ':schoolId',
                loadComponent: () =>
                    import('app/main/salary-constants/salary-constants.component').then(
                        m => m.SalaryConstantsComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439051', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'school-location',
        loadComponent: () =>
            import('app/main/school-location/school-location.component').then(m => m.SchoolLocationComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439031', // navigation-ams.json ID
        },
        children: [
            {
                path: ':schoolId',
                loadComponent: () =>
                    import('app/main/school-location/school-location.component').then(m => m.SchoolLocationComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
            },
        ],
    },
    {
        path: 'school-department',
        loadComponent: () =>
            import('app/main/school-department/school-department.component').then(m => m.SchoolDepartmentComponent),
        canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439032', // navigation-ams.json ID
        },
        children: [
            {
                path: ':schoolId',
                loadComponent: () =>
                    import('app/main/school-department/school-department.component').then(
                        m => m.SchoolDepartmentComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
            },
        ],
    },
    {
        path: 'item/list',
        loadComponent: () => import('app/main/item/item.component').then(m => m.ItemComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439095', // navigation-ams.json ID
        },
    },
    {
        path: 'cost-center',
        loadChildren: () => import('app/main/cost-center/cost-center.module').then(m => m.CostCenterModule),
        canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
    },
    {
        path: 'budget-excel-templates',
        loadChildren: () =>
            import('app/main/budget-excel-template/budget-excel-templates.module').then(
                m => m.BudgetExcelTemplatesModule
            ),
        canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
    },
    {
        path: 'modifier-salary',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/salary-modifier/salary-modifier.component').then(m => m.SalaryModifierComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439050', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/salary-modifier/form/modifier-form.component').then(m => m.ModifierFormComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439050', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/salary-modifier/form/modifier-form.component').then(m => m.ModifierFormComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439050', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'employee',
        children: [
            {
                path: 'salary-parameter-list/:type',
                loadComponent: () =>
                    import('app/main/employees/salary-parameter/salary-parameter.component').then(
                        m => m.SalaryParameterComponent
                    ),
            },
            {
                path: 'salary-parameter-all',
                loadComponent: () =>
                    import('app/main/employees/salary-parameter/salary-parameter.component').then(
                        m => m.SalaryParameterComponent
                    ),
            },
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/employees/employee-list/employee-list.component').then(
                        m => m.EmployeeListComponent
                    ),
                canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439052', // navigation-ams.json ID
                    type: 'info',
                },
            },
            {
                path: 'import',
                loadComponent: () =>
                    import('app/main/employees/employee-list/employee-list.component').then(
                        m => m.EmployeeListComponent
                    ),
                canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439252', // navigation-ams.json ID
                    type: 'import',
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/employees/employee-list/employee-form/employee-form.component').then(
                        m => m.EmployeeFormComponent
                    ),
                canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439052', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/employees/employee-list/employee-form/employee-form.component').then(
                        m => m.EmployeeFormComponent
                    ),
                canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439052', // navigation-ams.json ID
                },
            },
            {
                path: 'salary-parameter/:id',
                loadComponent: () =>
                    import(
                        'app/main/employees/salary-parameter/salary-parameter-form/salary-parameter-form.component'
                    ).then(m => m.SalaryParameterFormComponent),
            },
            {
                path: 'salary-parameter/state/:state',
                loadComponent: () =>
                    import(
                        'app/main/employees/salary-parameter/salary-parameter-form/salary-parameter-form.component'
                    ).then(m => m.SalaryParameterFormComponent),
            },
        ],

        canActivate: [AuthGuard],
    },
    {
        path: 'salary',
        loadComponent: () => import('app/main/employees/salary/salary.component').then(m => m.SalaryComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439054', // navigation-ams.json ID
        },
    },
    {
        path: 'salary-type/list',
        loadComponent: () => import('app/main/salary-type/salary-type.component').then(m => m.SalaryTypeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439060', // navigation-ams.json ID
        },
    },
    {
        path: 'exam',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/exams/exam-list/exam-list.component').then(m => m.ExamListComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439042', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/exams/exam-list/exam-form/exam-form.component').then(m => m.ExamFormComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439042', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/exams/exam-list/exam-form/exam-form.component').then(m => m.ExamFormComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439042', // navigation-ams.json ID
                },
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'notification',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/notifications/notification.component').then(m => m.NotificationsComponent),

                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439030', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/notifications/notification-form/notification-form.component').then(
                        m => m.NotificationFormComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439030', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/notifications/notification-form/notification-form.component').then(
                        m => m.NotificationFormComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439030', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'pre-registration',
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439044', // navigation-ams.json ID
        },
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/entrance-exam/approvement/registration-approvement.component').then(
                        m => m.RegistrationApprovementComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439044', // navigation-ams.json ID
                },
            },
            {
                path: 'complete',
                loadComponent: () =>
                    import('app/main/entrance-exam/complete/registration-complete.component').then(
                        m => m.RegistrationCompleteComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439045', // navigation-ams.json ID
                },
            },
            {
                path: 'pass',
                loadComponent: () =>
                    import('app/main/entrance-exam/exam-result/exam-result.component').then(m => m.ExamResultComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439046', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/entrance-exam/registration-edit/registration-edit.component').then(
                        m => m.RegistrationEditComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439044', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/entrance-exam/registration-edit/registration-edit.component').then(
                        m => m.RegistrationEditComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439044', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'countries/list',
        loadComponent: () => import('app/main/countries/countries.component').then(m => m.CountriesComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439014', // navigation-ams.json ID
        },
    },
    {
        path: 'entity-fields/list',
        loadComponent: () =>
            import('app/main/shared/entity-field/fields/entity-fields.component').then(m => m.EntityFieldsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '607f1f77bcf86cd799439014', // navigation-ams.json ID
        },
    },
    {
        path: 'entity-field-settings/list',
        loadComponent: () =>
            import('app/main/shared/entity-field/settings/entity-field-settings.component').then(
                m => m.EntityFieldSettingsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '607f1f77bcf86cd799439015', // navigation-ams.json ID
        },
    },
    {
        path: 'custom-field-groups/list',
        loadComponent: () =>
            import('app/main/custom-field-group/custom-field-group.component').then(m => m.CustomFieldGroupComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439325', // navigation-ams.json ID
        },
    },
    {
        path: 'cities/list',
        loadComponent: () => import('app/main/cities/city.component').then(m => m.CityComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439015', // navigation-ams.json ID
        },
    },
    {
        path: 'states/list',
        loadComponent: () => import('app/main/states/state.component').then(m => m.StateComponent),
        data: {
            permissionId: '507f1f77bcf86cd799439758', // navigation-ams.json ID
        },
    },
    {
        path: 'citizenships/list',
        loadComponent: () => import('app/main/citizenships/citizenship.component').then(m => m.CitizenshipComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439016', // navigation-ams.json ID
        },
    },
    {
        path: 'bbb-servers/list',
        loadComponent: () => import('app/main/bbb-server/bbb-server.component').then(m => m.BBBServerComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: { roles: ['ROLE_ADMIN'] },
    },
    {
        path: 'courses/list',
        loadComponent: () =>
            import('app/main/school-course/school-course.component').then(m => m.SchoolCourseComponent),
        canActivate: [AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439138', // navigation-ams.json ID
        },
    },
    {
        path: 'courses/syllabus/:id',
        loadComponent: () =>
            import('app/main/school-course/syllabus/syllabus.component').then(m => m.SyllabusComponent),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
            permissionId: '507f1f77bcf86cd799439138', // navigation-ams.json ID
        },
    },
    {
        path: 'courses/lessons/:id',
        loadComponent: () =>
            import('app/main/school-course/course-lessons/course-lessons.component').then(
                m => m.CourseLessonsComponent
            ),
        canActivate: [AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439138', // navigation-ams.json ID
        },
    },
    {
        path: 'grading-scheme/list',
        loadComponent: () => import('app/main/grade-scheme/grade-scheme.component').then(m => m.GradeSchemeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439296', // navigation-ams.json ID
        },
    },
    {
        path: 'grade-categories-template',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/grade-categories-template/grade-categories-template.component').then(
                        m => m.GradeCategoriesTemplateComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439196', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/grade-categories-template/form/grade-categories-template-form.component').then(
                        m => m.GradeCategoriesTemplateFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439196', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/grade-categories-template/form/grade-categories-template-form.component').then(
                        m => m.GradeCategoriesTemplateFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439196', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'grade-excuse-types/list',
        loadComponent: () =>
            import('app/main/grade-excuse-type/grade-excuse-type.component').then(m => m.GradeExcuseTypeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439197', // navigation-ams.json ID
        },
    },
    {
        path: 'nationality/list',
        loadComponent: () => import('app/main/nationality/nationality.component').then(m => m.NationalityComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439033', // navigation-ams.json ID
        },
    },
    {
        path: 'budget-accounts',
        loadChildren: () => import('app/main/budget-accounts/budget-accounts.module').then(m => m.BudgetAccountsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'budget-groups',
        loadChildren: () => import('app/main/budget-groups/budget-groups.module').then(m => m.BudgetGroupsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'budgets',
        loadChildren: () => import('app/main/budgets/budgets.module').then(m => m.BudgetsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'attendance-by-student',
        loadComponent: () =>
            import('app/main/attendance-by-student/attendance-by-student.component').then(
                m => m.AttendanceByStudentComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439189', // navigation-ams.json ID
        },
    },
    {
        path: 'attendance-by-course',
        loadComponent: () =>
            import('app/main/attendance-by-course/attendance-by-course.component').then(
                m => m.AttendanceByCourseComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439190', // navigation-ams.json ID
            fullPage: true,
        },
    },
    {
        path: 'attendance-by-teacher',
        loadComponent: () =>
            import('app/main/attendance-by-teacher/attendance-by-teacher.component').then(
                m => m.AttendanceByTeacherComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799433169', // navigation-ams.json ID
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'attendance-excuse/list',
        loadComponent: () =>
            import('app/main/attendance-excuse/attendance-excuse.component').then(m => m.AttendanceExcuseComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'attendance-excuse-request/list',
        loadComponent: () =>
            import('app/main/attendance-excuse-requests/attendance-excuse-requests.component').then(
                m => m.AttendanceExcuseRequestComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799433166', // navigation-ams.json ID
        },
    },
    {
        path: 'student-fees/list',
        loadComponent: () => import('app/main/student-fees/student-fees.component').then(m => m.StudentFeesComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439037', // navigation-ams.json ID
        },
    },
    {
        path: 'student-fees/finance/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/student-fees/student-fees-form/student-fees-form.component').then(
                m => m.StudentFeesFormComponent
            ),
        data: {
            type: 'finance', // navigation-ams.json ID
        },
    },
    {
        path: 'student-fees/info',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/student-fees/student-fees-form/student-fees-form.component').then(
                m => m.StudentFeesFormComponent
            ),
    },
    {
        path: 'student-fees/info/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/student-fees/student-fees-form/student-fees-form.component').then(
                m => m.StudentFeesFormComponent
            ),
    },
    {
        path: 'student-finance/active',
        loadComponent: () => import('app/main/student-fees/student-fees.component').then(m => m.StudentFeesComponent),
        data: { permissionId: '507f1f77bcf86cd799439078', finance: true },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'student-payment/list',
        loadComponent: () =>
            import('app/main/student-payment/student-payments.component').then(m => m.StudentPaymentsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439038', // navigation-ams.json ID
        },
    },
    {
        path: 'student-payment/info/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/student-payment/form/student-payment-form.component').then(
                m => m.StudentPaymentFormComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799439038', // navigation-ams.json ID
        },
    },
    {
        path: 'student-payment-report/turnover',
        loadComponent: () =>
            import('app/main/student-payment-turnover-report/student-payment-turnover-report.component').then(
                m => m.StudentPaymentTurnoverReportComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439199', // navigation-ams.json ID
        },
    },
    {
        path: 'bank-account/list',
        loadComponent: () => import('app/main/bank-accounts/bank-account.component').then(m => m.BankAccountComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439024', // navigation-ams.json ID
        },
    },
    {
        path: 'income-plans',
        loadChildren: () => import('app/main/income-plans/income-plans.module').then(m => m.IncomePlansModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'positions',
        children: [
            {
                path: 'list',
                loadComponent: () => import('app/main/positions/positions.component').then(m => m.PositionsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439049', // navigation-ams.json ID
                },
            },
            {
                path: 'base-salary/:positionId',
                loadComponent: () =>
                    import('app/main/positions/base-salary/salary-experience.component').then(
                        m => m.SalaryExperienceComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439049', // navigation-ams.json ID
                },
            },
            {
                path: 'base-salary/:positionId',
                loadComponent: () =>
                    import('app/main/positions/base-salary/salary-experience.component').then(
                        m => m.SalaryExperienceComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439049', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'work-calendar/list',
        loadComponent: () =>
            import('app/main/work-calendar/work-calendar.component').then(m => m.WorkCalendarComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439061', // navigation-ams.json ID
        },
    },
    {
        path: 'work-calendar/info',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/work-calendar/work-calendar-form/work-calendar-form.component').then(
                m => m.WorkCalendarFormComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799439061', // navigation-ams.json ID
        },
    },
    {
        path: 'work-calendar/info/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/work-calendar/work-calendar-form/work-calendar-form.component').then(
                m => m.WorkCalendarFormComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799439061', // navigation-ams.json ID
        },
    },
    {
        path: 'time-periods/list',
        loadComponent: () => import('app/main/time-periods/time-periods.component').then(m => m.TimePeriodsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439139', // navigation-ams.json ID
        },
    },
    {
        path: 'timesheet-multiplier/list',
        loadComponent: () =>
            import('app/main/timesheet-multiplier/timesheet-multiplier.component').then(
                m => m.TimesheetMultiplierComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439062', // navigation-ams.json ID
        },
    },
    {
        path: 'timesheet-monthly',
        loadComponent: () =>
            import('app/main/employees/timesheet-monthly/timesheet-monthly.component').then(
                m => m.TimesheetMonthlyComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439063', // navigation-ams.json ID
        },
    },
    {
        path: 'irregular-work-plan',
        loadComponent: () =>
            import('app/main/employees/irregular-work-plan/irregular-work-plan.component').then(
                m => m.IrregularWorkPlanComponent
            ),
        data: {
            permissionId: '507f1f77bcf86cd799439064', // navigation-ams.json ID
        },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'daily-timesheet-actual',
        loadComponent: () =>
            import('app/main/employees/daily-timesheet-actual/daily-timesheet-actual.component').then(
                m => m.DailyTimesheetActualComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799438065', // navigation-ams.json ID
        },
    },
    {
        path: 'budget-salary-plans',
        loadChildren: () => import('app/main/salary-plans/salary-plans.module').then(m => m.SalaryPlansModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'budget-balance-report',
        loadChildren: () =>
            import('app/main/budget-balance-report/budget-balance-report.module').then(
                m => m.BudgetBalanceReportModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'item-transaction',
        loadComponent: () =>
            import('app/main/item-transactions/item-transaction.component').then(m => m.ItemTransactionComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439590', // navigation-ams.json ID
        },
        children: [
            {
                path: 'info/:entityType/:id',
                canActivate: [UserRouteAccessService, AuthGuard],
                loadComponent: () =>
                    import('app/main/item-transactions/item-transaction-form/item-transaction-form.component').then(
                        m => m.ItemTransactionFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439590', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'attestation/list',
        loadComponent: () => import('app/main/attestation/attestation.component').then(m => m.AttestationComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439067', // navigation-ams.json ID
        },
    },
    {
        path: 'position-category/list',
        loadComponent: () =>
            import('app/main/position-category/position-category.component').then(m => m.PositionCategoryComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439068', // navigation-ams.json ID
        },
    },
    {
        path: 'dismissal-article/list',
        loadComponent: () =>
            import('app/main/dismissal-article/dismissal-article.component').then(m => m.DismissalArticleComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439069', // navigation-ams.json ID
        },
    },
    {
        path: 'item-category/list',
        loadComponent: () =>
            import('app/main/item-category/item-category.component').then(m => m.ItemCategoryComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439093', // navigation-ams.json ID
        },
    },
    {
        path: 'item-type',
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439094', // navigation-ams.json ID
        },
        children: [
            {
                path: '',
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439094', // navigation-ams.json ID
                },
                loadComponent: () => import('app/main/item-type/item-type.component').then(m => m.ItemTypeComponent),
            },
            {
                path: 'info',
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439094', // navigation-ams.json ID
                },
                loadComponent: () =>
                    import('app/main/item-type/form/item-type-form.component').then(m => m.ItemTypeFormComponent),
            },
            {
                path: 'info/:id',
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439094', // navigation-ams.json ID
                },
                loadComponent: () =>
                    import('app/main/item-type/form/item-type-form.component').then(m => m.ItemTypeFormComponent),
            },
        ],
    },
    {
        path: 'budget-projects',
        loadChildren: () => import('app/main/budget-projects/budget-project.module').then(m => m.BudgetProjectModule),
        canActivate: [AuthGuard, MustHaveDefaultSchoolGuard],
    },
    {
        path: 'budget-balance-actual-report',
        loadChildren: () =>
            import('app/main/budget-balance-actual-report/budget-balance-actual-report.module').then(
                m => m.BudgetBalanceActualReportModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'employee-position',
        loadComponent: () =>
            import('app/main/employee-position/employee-position.component').then(m => m.EmployeePositionComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439040', // navigation-ams.json ID
        },
    },
    {
        path: 'budget-excel-report',
        loadChildren: () =>
            import('app/main/budget-report/budget-excel-report.module').then(m => m.BudgetExcelReportModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'student-payment-report/list',
        loadComponent: () =>
            import('app/main/student-payment-report/student-payment-report.component').then(
                m => m.StudentPaymentReportComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439099', // navigation-ams.json ID
        },
    },
    {
        path: 'job-management',
        loadChildren: () => import('app/main/job-management/job-management.module').then(m => m.JobManagementModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'school-balance-report/list',
        loadComponent: () =>
            import('app/main/school-balance-report/school-balance-report.component').then(
                m => m.SchoolBalanceReportComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439200', // navigation-ams.json ID
        },
    },
    {
        path: 'budget-close-accounts',
        loadChildren: () => import('app/main/budget-close/budget-close.module').then(m => m.BudgetCloseModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'budget-expense-transfer',
        loadChildren: () =>
            import('app/main/budget-expense-transfer/budget-expense-transfer.module').then(
                m => m.BudgetExpenseTransferModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'budget-expense-request-chain',
        loadChildren: () =>
            import('app/main/budget-expense-request-chain/budget-expense-request-chain.module').then(
                m => m.BudgetExpenseRequestChainModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'daily-attendance',
        loadComponent: () =>
            import('app/main/daily-attendance/daily-attendance.component').then(m => m.DailyAttedanceComponent),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'daily-attendance-report',
        loadComponent: () =>
            import('app/main/class-daily-attendance-report/daily-attendance-report.component').then(
                m => m.DailyAttendanceReportComponent
            ),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'budget-expense-request/sent',
        loadChildren: () =>
            import('app/main/budget-expense-request/request-sent/budget-expense-request-sent.module').then(
                m => m.BudgetExpenseRequestSentModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'budget-expense-request/inbox',
        loadChildren: () =>
            import('app/main/budget-expense-request/request-inbox/request-inbox.module').then(
                m => m.BudgetRequestInboxModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'budget-expense-request/outbox',
        loadChildren: () =>
            import('app/main/budget-expense-request/request-inbox/request-inbox.module').then(
                m => m.BudgetRequestInboxModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'meetings',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/education/meetings/meetings.component').then(m => m.MeetingsComponent),
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/education/meetings/info/meetings-info.component').then(
                        m => m.MeetingsInfoComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439140', // navigation-ams.json ID
                },
            },
            {
                path: 'new',
                loadComponent: () =>
                    import('app/main/education/meetings/form/meetings-form.component').then(
                        m => m.MeetingsFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439140', // navigation-ams.json ID
                },
            },
            {
                path: ':id',
                loadComponent: () =>
                    import('app/main/education/meetings/form/meetings-form.component').then(
                        m => m.MeetingsFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439140', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'recordings',
        loadComponent: () =>
            import('app/main/education/recordings/recordings.component').then(m => m.RecordingsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439149', // navigation-ams.json ID
        },
    },
    {
        path: 'course-enrollment',
        loadComponent: () =>
            import('app/main/student-list/students-list-class.component').then(m => m.StudentsListClassComponent),
        data: {
            type: 'course-assign',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'student-schedule',
        loadComponent: () =>
            import('app/main/student-list/students-list-class.component').then(m => m.StudentsListClassComponent),
        data: {
            type: 'student-schedule',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'student-alert',
        loadComponent: () =>
            import('app/main/student-list/students-list-class.component').then(m => m.StudentsListClassComponent),
        data: {
            type: 'student-alert',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'course-self-enrollments',
        loadComponent: () =>
            import('app/main/course-self-enrollment/course-enrollments.component').then(
                m => m.CourseEnrollmentsComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'student-groups',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/student-groups/student-groups.component').then(m => m.StudentGroupsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439144', // navigation-ams.json ID
                },
            },
            {
                path: 'students/:id',
                canActivate: [UserRouteAccessService, AuthGuard],
                loadComponent: () =>
                    import('app/main/student-groups/group-students/group-students.component').then(
                        m => m.GroupStudentsComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439144', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'students-by-class',
        loadComponent: () =>
            import('app/main/student-list/students-list-class.component').then(m => m.StudentsListClassComponent),
        data: {
            type: 'students-by-class',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'student-program-service',
        loadComponent: () =>
            import('app/main/student-list/students-list-class.component').then(m => m.StudentsListClassComponent),
        data: {
            type: 'student-program-service',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'announcements',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/announcement/announcement.component').then(m => m.AnnouncementComponent),
                canActivate: [AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439171', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/announcement/announcement-form/announcement-form.component').then(
                        m => m.AnnouncementFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439171', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/announcement/announcement-form/announcement-form.component').then(
                        m => m.AnnouncementFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439171', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'time-table/list',
        loadComponent: () => import('app/main/time-table/time-table.component').then(m => m.TimeTableComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439142', // navigation-ams.json ID
        },
    },
    {
        path: 'time-table/info',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/time-table/time-table-form/time-table-form.component').then(m => m.TimeTableFormComponent),
        data: {
            permissionId: '507f1f77bcf86cd799439142', // navigation-ams.json ID
        },
    },
    {
        path: 'time-table/info/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/time-table/time-table-form/time-table-form.component').then(m => m.TimeTableFormComponent),
        data: {
            permissionId: '507f1f77bcf86cd799439142', // navigation-ams.json ID
        },
    },

    {
        path: 'user-messages/list/:id',
        loadComponent: () => import('app/main/user-message/user-message.component').then(m => m.UserMessageComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'user-messages/new',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/user-message/form/user-message-form.component').then(m => m.UserMessageFormComponent),
    },
    {
        path: 'user-messages/reply/:id',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import('app/main/user-message/reply-form/user-message-reply-form.component').then(
                m => m.UserMessageReplyFormComponent
            ),
    },
    {
        path: 'course-attendance-report',
        loadComponent: () =>
            import('app/main/course-attendance-report/course-attendance-report.component').then(
                m => m.CourseAttendanceReportComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799433159', // navigation-ams.json ID
        },
    },
    {
        path: 'user-courses',
        loadComponent: () => import('app/main/my-page/courses/courses.component').then(m => m.CoursesComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            fullPage: false,
        },
    },
    {
        path: 'student-course-page',
        loadChildren: () =>
            import('app/main/my-page/courses/course-page/course-page.module').then(m => m.CoursePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'student-attendance',
        loadComponent: () =>
            import('app/main/my-page/attendance/attendance.component').then(m => m.AttendanceComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'student-grading',
        loadComponent: () =>
            import('app/main/student-grades/student-grades.component').then(m => m.StudentGradesComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'student-transcript',
        loadComponent: () =>
            import('app/main/student-transcripts/student-transcripts.component').then(
                m => m.StudentTranscriptsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            preload: true,
            permissionId: '507f1f77bcf86cd799439290', // navigation-ams.json ID
        },
    },
    {
        path: 'student-assignment',
        loadComponent: () =>
            import('app/layout/components/content/assignment-panel-student/assignment-panel-student.component').then(
                m => m.AssignmentPanelStudentComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'teacher-course-page',
        loadChildren: () =>
            import('app/main/my-page/courses/teacher-course-page/teacher-course-page.module').then(
                m => m.TeacherCoursePageModule
            ),

        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'teacher-course-page/student-course',
        loadChildren: () =>
            import('app/main/my-page/courses/teacher-course-page/student-course-page/student-course-page.module').then(
                m => m.StudentCoursePageModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'courses-calendar',
        loadComponent: () =>
            import('app/main/my-page/courses/calendar/courses-calendar.component').then(
                m => m.CoursesCalendarComponent
            ),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'teacher-attendance',
        loadComponent: () =>
            import('app/main/attendance-by-course/attendance-by-course.component').then(
                m => m.AttendanceByCourseComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'student-attendance-report',
        loadComponent: () =>
            import('app/main/attendance-student-report/student-attendance-report.component').then(
                m => m.StudentAttendanceReportComponent
            ),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'teacher-assignments',
        loadComponent: () =>
            import('app/main/course-assignment/course-assignment.component').then(m => m.CourseAssignmentComponent),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'teacher-grades',
        loadComponent: () =>
            import('app/main/teacher-grades/teacher-grades.component').then(m => m.TeacherGradesComponent),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'class-attendance-report',
        loadComponent: () =>
            import('app/main/class-attendance-report/class-attendance-report.component').then(
                m => m.ClassAttendanceReportComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799433160', // navigation-ams.json ID
        },
    },
    {
        path: 'attendance-charts',
        loadComponent: () =>
            import('app/main/analytics/attendance-charts/attendance-charts.component').then(
                m => m.AttendanceChartsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439213', // navigation-ams.json ID
        },
    },
    {
        path: 'attendance-percentages',
        loadComponent: () =>
            import('app/main/analytics/attendance-tables/attendance-tables.component').then(
                m => m.AttendanceTablesComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439214', // navigation-ams.json ID
        },
    },
    {
        path: 'master-schedule',
        loadComponent: () =>
            import('app/main/master-schedule/master-schedule.component').then(m => m.MasterScheduleComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439178', // navigation-ams.json ID
        },
    },
    {
        path: 'course-schedule',
        loadComponent: () =>
            import('app/main/education/course-schedule-page/course-schedule-page.component').then(
                m => m.CourseSchedulePageComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439145', // navigation-ams.json ID
        },
    },
    {
        path: 'meeting-attendance-report',
        loadComponent: () =>
            import('app/main/education/online-attendance/online-attendance.component').then(
                m => m.OnlineAttendanceComponent
            ),
        data: {
            type: 'STUDENT',
            permissionIds: ['507f1f77bcf86cd799439146', '507f1f77bcf86cd799439148'],
        },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'course-moderator-attendance-report',
        loadComponent: () =>
            import('app/main/education/online-attendance/online-attendance.component').then(
                m => m.OnlineAttendanceComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            type: 'MODERATOR',
            permissionIds: ['507f1f77bcf86cd799439146', '507f1f77bcf86cd799439148'],
        },
    },
    {
        path: 'courses-admin',
        loadComponent: () =>
            import('app/main/education/courses-admin/courses-admin.component').then(m => m.CoursesAdminComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439147', // navigation-ams.json ID
        },
    },
    {
        path: 'course-moderators/list',
        loadComponent: () =>
            import('app/main/education/course-moderators/course-moderators.component').then(
                m => m.CourseModeratorsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439159', // navigation-ams.json ID
        },
    },
    {
        path: 'grading-after-due-date',
        loadComponent: () =>
            import('app/main/education/grading-settings-after-due-date/grading-settings-after-due-date.component').then(
                m => m.GradingSettingsAfterDueDateComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439159', // navigation-ams.json ID
        },
    },
    {
        path: 'grading-rubrics',
        loadComponent: () =>
            import('app/main/grading-rubric/grading-rubric.component').then(m => m.GradingRubricComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439310', // navigation-ams.json ID
        },
    },
    {
        path: 'course-assignments/list',
        loadComponent: () =>
            import('app/main/course-assignment/course-assignment.component').then(m => m.CourseAssignmentComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            fullPage: true,
            permissionId: '507f1f77bcf86cd799439150', // navigation-ams.json ID
        },
    },
    {
        path: 'course-assignments/info',
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439150', // navigation-ams.json ID
        },
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('app/main/course-assignment/form/course-assignment-form.component').then(
                        m => m.CourseAssignmentFormComponent
                    ),
                canActivate: [UserRouteAccessService],
            },
            {
                path: ':id',
                loadComponent: () =>
                    import('app/main/course-assignment/form/course-assignment-form.component').then(
                        m => m.CourseAssignmentFormComponent
                    ),

                canActivate: [UserRouteAccessService],
            },
            {
                path: ':id/:academicPeriodId',
                canActivate: [UserRouteAccessService],
                loadComponent: () =>
                    import('app/main/course-assignment/form/course-assignment-form.component').then(
                        m => m.CourseAssignmentFormComponent
                    ),
            },
        ],
    },
    {
        path: 'course-assignments/review-submissions',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('app/main/course-assignment/review-submissions/review-submissions.component').then(
                        m => m.ReviewSubmissionsComponent
                    ),
                canActivate: [UserRouteAccessService],
            },
            {
                path: ':assignmentId',
                loadComponent: () =>
                    import('app/main/course-assignment/review-submissions/review-submissions.component').then(
                        m => m.ReviewSubmissionsComponent
                    ),
                canActivate: [UserRouteAccessService],
            },
        ],
    },
    {
        path: 'sms-log/list',
        loadComponent: () => import('app/main/sms-log/sms-log.component').then(m => m.SmsLogComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439177', // navigation-ams.json ID
        },
    },
    {
        path: 'my-assignments',
        loadComponent: () =>
            import('app/main/education/my-assignments/my-assignments.component').then(m => m.MyAssignmentsComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'my-assignments/info',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('app/main/education/my-assignments/form/page/my-assignments-form-page.component').then(
                        m => m.MyAssignmentsFormPageComponent
                    ),
                canActivate: [UserRouteAccessService],
            },
            {
                path: ':id',
                loadComponent: () =>
                    import('app/main/education/my-assignments/form/page/my-assignments-form-page.component').then(
                        m => m.MyAssignmentsFormPageComponent
                    ),
                canActivate: [UserRouteAccessService],
            },
        ],
    },
    {
        path: 'timetable-report/list',
        loadComponent: () =>
            import('app/main/timetable-report/timetable-report.component').then(m => m.TimetableReportComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439181', // navigation-ams.json ID
        },
    },
    {
        path: 'teacher-conflicts/list',
        loadComponent: () =>
            import('app/main/timetable-teacher-conflicts/timetable-teacher-conflicts.component').then(
                m => m.TimeTableTeacherConflictsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439250', // navigation-ams.json ID
        },
    },
    {
        path: 'academic-calendar/list',
        loadComponent: () =>
            import('app/main/academic-calendar/academic-calendar.component').then(m => m.AcademicCalendarComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439129', // navigation-ams.json ID
        },
    },
    {
        path: 'gradebook',
        loadComponent: () => import('app/main/gradebook/gradebook-page.component').then(m => m.GradebookPageComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439182', // navigation-ams.json ID
        },
    },
    {
        path: 'course-term-grade',
        loadComponent: () =>
            import('app/main/course-term-grade/course-term-grade-page.component').then(
                m => m.CourseTermGradePageComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439182', // navigation-ams.json ID
        },
    },
    {
        path: 'imported-data-budget-accounts',
        loadComponent: () =>
            import('app/main/imported-documents/imported-documents.component').then(m => m.ImportedDocumentsComponent),
        data: {
            type: ImportedDocumentType.BUDGET_ACCOUNT,
            permissionIds: [
                '507f1f77bcf86cd799439152',
                '507f1f77bcf86cd799439244',
                '507f1f77bcf86cd799439245',
                '507f1f77bcf86cd799439156',
            ], // navigation-ams.json ID
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'imported-data-students',
        loadComponent: () =>
            import('app/main/imported-documents/imported-documents.component').then(m => m.ImportedDocumentsComponent),
        data: {
            type: ImportedDocumentType.STUDENT,
            permissionIds: [
                '507f1f77bcf86cd799439152',
                '507f1f77bcf86cd799439244',
                '507f1f77bcf86cd799439245',
                '507f1f77bcf86cd799439156',
            ], // navigation-ams.json ID
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'imported-data-users',
        loadComponent: () =>
            import('app/main/imported-documents/imported-documents.component').then(m => m.ImportedDocumentsComponent),
        data: {
            type: ImportedDocumentType.USER,
            permissionIds: [
                '507f1f77bcf86cd799439152',
                '507f1f77bcf86cd799439244',
                '507f1f77bcf86cd799439245',
                '507f1f77bcf86cd799439156',
            ], // navigation-ams.json ID
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'imported-data-employees',
        loadComponent: () =>
            import('app/main/imported-documents/imported-documents.component').then(m => m.ImportedDocumentsComponent),
        data: {
            type: ImportedDocumentType.EMPLOYEE,
            permissionIds: [
                '507f1f77bcf86cd799439152',
                '507f1f77bcf86cd799439244',
                '507f1f77bcf86cd799439245',
                '507f1f77bcf86cd799439156',
            ], // navigation-ams.json ID
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'my-page/:id',
        loadComponent: () => import('app/main/my-page/my-page.component').then(m => m.MyPageComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439135', // navigation-ams.json ID
        },
    },
    {
        path: 'question',
        children: [
            {
                path: 'quiz',
                loadComponent: () =>
                    import('app/main/question-bank/questions/questions.component').then(m => m.QuestionsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    intent: 'QUIZ',
                    permissionId: '507f1f77bcf86cd799439154', // navigation-ams.json ID
                },
            },
            {
                path: 'survey',
                loadComponent: () =>
                    import('app/main/question-bank/questions/questions.component').then(m => m.QuestionsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    intent: 'SURVEY',
                    permissionId: '507f1f77bcf86cd799439154', // navigation-ams.json ID
                },
            },
            {
                path: 'quiz/:subjectId',
                loadComponent: () =>
                    import('app/main/question-bank/questions/questions.component').then(m => m.QuestionsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    intent: 'QUIZ',
                    permissionId: '507f1f77bcf86cd799439154', // navigation-ams.json ID
                },
            },
            {
                path: 'survey/:subjectId',
                loadComponent: () =>
                    import('app/main/question-bank/questions/questions.component').then(m => m.QuestionsComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    intent: 'SURVEY',
                    permissionId: '507f1f77bcf86cd799439154', // navigation-ams.json ID
                },
            },
            {
                path: 'info',
                canActivate: [UserRouteAccessService, AuthGuard],
                loadComponent: () =>
                    import(
                        'app/main/question-bank/questions/form/question-form-page/question-form-page.component'
                    ).then(m => m.QuestionFormPageComponent),
                data: {
                    permissionId: '507f1f77bcf86cd799439154', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id/:type/:isDuplicate',
                canActivate: [UserRouteAccessService, AuthGuard],
                loadComponent: () =>
                    import(
                        'app/main/question-bank/questions/form/question-form-page/question-form-page.component'
                    ).then(m => m.QuestionFormPageComponent),
                data: {
                    permissionId: '507f1f77bcf86cd799439154', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'timetable-classes',
        loadChildren: () => import('app/main/timetable/classes/classes.module').then(m => m.ClassesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-rooms',
        loadChildren: () => import('app/main/timetable/rooms/rooms.module').then(m => m.RoomsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-teachers',
        loadChildren: () => import('app/main/timetable/teachers/teachers.module').then(m => m.TeachersModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-subjects',
        loadChildren: () => import('app/main/timetable/subjects/subjects.module').then(m => m.TimetableSubjectsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-courses',
        loadChildren: () => import('app/main/timetable/courses/courses.module').then(m => m.CoursesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-projects',
        loadChildren: () => import('app/main/timetable/projects/projects.module').then(m => m.ProjectsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-constraints',
        loadChildren: () => import('app/main/timetable/constraints/constraints.module').then(m => m.ConstraintsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-trials',
        loadChildren: () => import('app/main/timetable/trials/trials.module').then(m => m.TrialsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'trial/:trialId/timetable/:id',
        loadChildren: () => import('app/main/timetable/trials/timetable/timetable.module').then(m => m.TimetableModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'timetable-panel',
        loadChildren: () => import('app/main/timetable/panel/panel.module').then(m => m.PanelModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'teacher-page',
        loadComponent: () => import('app/main/teacher-page/teacher-page.component').then(m => m.TeacherPageComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439591', // navigation-ams.json ID
        },
    },
    {
        path: 'teacher-page/student-course/:id/:courseId',
        canActivate: [UserRouteAccessService, AuthGuard],
        loadComponent: () =>
            import(
                'app/main/my-page/courses/teacher-course-page/student-course-page/student-course-page.component'
            ).then(m => m.StudentCoursePageComponent),
        data: {
            permissionId: '507f1f77bcf86cd799439150', // navigation-ams.json ID
        },
    },
    {
        path: 'teacher-events/info/:id',
        loadComponent: () =>
            import('app/main/teacher-page/teacher-events/event-info/event-info.component').then(
                m => m.EventInfoComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439135', // navigation-ams.json ID
        },
    },
    {
        path: 'quiz/:id/:quizId',
        loadComponent: () => import('app/main/quiz-form/quiz-form.component').then(m => m.QuizFormComponent),
        data: {
            fullPage: true,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'meeting-join/:id',
        loadComponent: () => import('app/main/meeting-join/meeting-join.component').then(m => m.MeetingJoinComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'class-grade-report',
        loadComponent: () =>
            import('app/main/class-grade-report-page/class-grade-report-page.component').then(
                m => m.ClassGradeReportPageComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439182', // navigation-ams.json ID
        },
    },
    {
        path: 'education-standard',
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439210', // navigation-ams.json ID
        },
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/education-standard/education-standard.component').then(
                        m => m.EducationStandardComponent
                    ),

                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439210', // navigation-ams.json ID
                },
            },
            {
                path: 'detail/:educationStandardId',
                loadComponent: () =>
                    import('app/main/education-standard/detail/education-standard-detail.component').then(
                        m => m.EducationStandardDetailComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionId: '507f1f77bcf86cd799439210', // navigation-ams.json ID
                },
            },
        ],
    },

    {
        path: 'transcript',
        loadComponent: () => import('app/main/transcript/transcript.component').then(m => m.TranscriptComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439185', // navigation-ams.json ID
        },
    },

    {
        path: 'database-files',
        loadComponent: () =>
            import('app/main/admin/database-files/database-file.component').then(m => m.DatabaseFileComponent),
        canActivate: [AuthGuard],
        data: { roles: ['ROLE_ADMIN', 'ROLE_TENANT_ADMIN'] },
    },
    {
        path: 'report-card/academic-year',
        loadComponent: () => import('app/main/report-card/report-card.component').then(m => m.ReportCardComponent),
        data: {
            type: 'academic-year',
            permissionId: '507f1f77bcf86cd799439186', // navigation-ams.json ID
        },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'report-card/period',
        loadComponent: () => import('app/main/report-card/report-card.component').then(m => m.ReportCardComponent),
        data: {
            type: 'period',
            permissionId: '507f1f77bcf86cd799439186', // navigation-ams.json ID
        },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'education-reports',
        loadChildren: () =>
            import('app/main/education/reports/education-reports.module').then(m => m.EducationReportsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'report-card-generation/academic-year',
        loadComponent: () =>
            import('app/main/report-card/report-card-generation/report-card-generation.component').then(
                m => m.ReportCardGenerationComponent
            ),
        data: {
            type: 'academic-year',
            permissionId: '507f1f77bcf86cd799439186', // navigation-ams.json ID
        },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'report-card-generation/period',
        loadComponent: () =>
            import('app/main/report-card/report-card-generation/report-card-generation.component').then(
                m => m.ReportCardGenerationComponent
            ),
        data: {
            type: 'period',
            permissionId: '507f1f77bcf86cd799439186', // navigation-ams.json ID
        },
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'home',
        loadComponent: () =>
            import('app/main/head-teacher-home/head-teacher-home.component').then(m => m.HeadTeacherHomeComponent),
        canMatch: [isHeadTeacher],
        canActivate: [AuthGuard],
        data: {
            fullPage: false,
        },
    },
    {
        path: 'home',
        loadComponent: () =>
            import('app/main/incident/home/incident-home.component').then(m => m.IncidentHomeComponent),
        canMatch: [isIncidentManager],
        canActivate: [AuthGuard],
        data: {
            fullPage: false,
        },
    },
    {
        path: 'contract-templates',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/contract-templates/contract-templates.component').then(
                        m => m.ContractTemplatesComponent
                    ),

                data: {
                    permissionIds: ['507f1f77bcf86cd799439161'],
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/contract-templates/form/contract-templates-form.component').then(
                        m => m.ContractTemplatesFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439161', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/contract-templates/form/contract-templates-form.component').then(
                        m => m.ContractTemplatesFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439161', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'student-grades',
        loadComponent: () =>
            import('app/main/analytics/student-grades/student-grades.component').then(m => m.StudentGradesComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'grade-charts',
        loadComponent: () =>
            import('app/main/analytics/grade-chart-report/grade-chart-report.component').then(
                m => m.GradeChartReportComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439215', // navigation-ams.json ID
        },
    },
    {
        path: 'surveys/list',
        loadComponent: () => import('app/main/survey/list/survey.component').then(m => m.SurveyComponent),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'surveys/list/survey-submissions/survey/:id',
        loadComponent: () =>
            import('app/main/survey/list/form/submissions/survey-submissions.component').then(
                m => m.SurveySubmissionsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'surveys/list/survey-submission/submission/:submissionId',
        loadComponent: () =>
            import('app/main/survey/list/form/submissions/submission-view/survey-submission.component').then(
                m => m.SurveySubmissionComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'surveys/list/info',
        loadComponent: () => import('app/main/survey/list/form/survey-form.component').then(m => m.SurveyFormComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'surveys/list/info/:id',
        loadComponent: () => import('app/main/survey/list/form/survey-form.component').then(m => m.SurveyFormComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'surveys/view/:id',
        loadComponent: () =>
            import('app/main/survey/my-surveys/survey-form/survey-form.component').then(m => m.SurveyFormComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'surveys/surveys-for-me',
        loadComponent: () => import('app/main/survey/my-surveys/my-survey.component').then(m => m.MySurveyComponent),
        canActivate: [AuthGuard],
        data: {
            fullPage: true,
        },
    },
    {
        path: 'surveys/surveys-for-me/view/:id',
        loadComponent: () =>
            import('app/main/survey/my-surveys/survey-form/survey-form.component').then(m => m.SurveyFormComponent),
        canActivate: [AuthGuard],
    },

    {
        path: 'certificates/list',
        loadComponent: () =>
            import('app/main/certificate-type/certificates-type.component').then(m => m.CertificatesTypeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439217', // navigation-ams.json ID
        },
    },
    {
        path: 'certify-students',
        loadComponent: () =>
            import('app/main/certify-students/certify-students.component').then(m => m.CertifyStudentsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439218', // navigation-ams.json ID
        },
    },
    {
        path: 'student-certificates',
        loadComponent: () =>
            import('app/main/student-certificates/student-certificates.component').then(
                m => m.StudentCertificatesComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'my-certificates',
        loadComponent: () =>
            import('app/main/student-certificates/student-certificates.component').then(
                m => m.StudentCertificatesComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            type: 'STUDENT',
        },
    },
    {
        path: 'online-users-list',
        loadComponent: () =>
            import('app/main/online-users/list/online-users-list.component').then(m => m.OnlineUsersListComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
    },
    {
        path: 'incident-location/list',
        loadComponent: () =>
            import('app/main/incident/location/incident-location.component').then(m => m.IncidentLocationComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439167', // navigation-ams.json ID
        },
    },
    {
        path: 'incident-types/list',
        loadComponent: () =>
            import('app/main/incident/type/incident-type.component').then(m => m.IncidentTypeComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439172', // navigation-ams.json ID
        },
    },
    {
        path: 'incident-action/list',
        loadComponent: () => import('app/main/incident/action/actions.component').then(m => m.ActionsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439193', // navigation-ams.json ID
        },
    },
    {
        path: 'incident-behavior/list',
        loadComponent: () => import('app/main/incident/behavior/behaviors.component').then(m => m.BehaviorsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439194', // navigation-ams.json ID
        },
    },
    {
        path: 'incident',
        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/incident/incident-list/incident-list.component').then(
                        m => m.IncidentListComponent
                    ),
                canActivate: [AuthGuard],
                data: {
                    permissionIds: ['507f1f77bcf86cd799439169'],
                },
            },
            {
                path: 'info',
                loadComponent: () =>
                    import('app/main/incident/incident-list/form/incident-form.component').then(
                        m => m.IncidentFormComponent
                    ),
                data: {
                    permissionId: '507f1f77bcf86cd799439169', // navigation-ams.json ID
                    fullPage: true,
                },
            },
            {
                path: 'info/:id',
                loadComponent: () =>
                    import('app/main/incident/incident-list/form/incident-form.component').then(
                        m => m.IncidentFormComponent
                    ),
                data: {
                    fullPage: true,
                    permissionId: '507f1f77bcf86cd799439169', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'student-course-conflict/list',
        loadComponent: () =>
            import('app/main/course-conflict-report/student/student-course-conflict.component').then(
                m => m.StudentCourseConflictComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439181', // navigation-ams.json ID
        },
    },
    {
        path: 'incident/student-points',
        loadComponent: () =>
            import('app/main/incident-student-points/incident-student-points.component').then(
                m => m.IncidentStudentPointsComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439230', // navigation-ams.json ID
        },
    },
    {
        path: 'orders',
        children: [
            {
                path: '',
                loadComponent: () => import('app/main/orders/orders.component').then(m => m.OrdersComponent),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionIds: ['609f1f77bcf86cd799439018'],
                },
            },
            {
                path: 'info',
                canActivate: [UserRouteAccessService, AuthGuard],
                loadComponent: () =>
                    import('app/main/orders/order-form/order-form.component').then(m => m.OrderFormComponent),
                data: {
                    permissionId: '609f1f77bcf86cd799439018', // navigation-ams.json ID
                },
            },
            {
                path: 'info/:id',
                canActivate: [UserRouteAccessService, AuthGuard],
                loadComponent: () =>
                    import('app/main/orders/order-form/order-form.component').then(m => m.OrderFormComponent),
                data: {
                    permissionId: '609f1f77bcf86cd799439018', // navigation-ams.json ID
                },
            },
        ],
    },
    {
        path: 'scorm-exporter',
        loadComponent: () =>
            import('app/main/scorm-exporter/scorm-exporter.component').then(m => m.ScormExporterComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'syllabus-preview/:courseId',
        loadComponent: () =>
            import('app/main/school-course/syllabus/syllabus-preview/syllabus-preview.component').then(
                m => m.SyllabusPreviewComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'education-reports',
        loadChildren: () =>
            import('app/main/education/reports/education-reports.module').then(m => m.EducationReportsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'groups',
        loadChildren: () => import('app/main/groups/groups.component.routes'),
    },
    {
        path: 'student-admin/list',
        loadComponent: () =>
            import('app/main/students/student-admin-list/student-admin-list.component').then(
                m => m.StudentAdminListComponent
            ),
    },
    {
        path: 'medical-profile',

        children: [
            {
                path: 'list',
                loadComponent: () =>
                    import('app/main/medical-profile-list/medical-profile-list.component').then(
                        m => m.MedicalProfileListComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionIds: ['507f2f77bcf86cd799439144'],
                },
            },
            {
                path: 'form/:id',
                loadComponent: () =>
                    import('app/main/medical-profile-list/medical-profile-form/medical-profile-form.component').then(
                        m => m.MedicalProfileFormComponent
                    ),
                canActivate: [UserRouteAccessService, AuthGuard],
                data: {
                    permissionIds: ['507f2f77bcf86cd799439144'],
                },
            },
        ],
    },
    {
        path: 'medical-dashboard',
        loadComponent: () =>
            import('app/main/medical-dashboard/medical-dashboard.component').then(m => m.MedicalDashboardComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionIds: ['507f2f77bcf96cd799439145'],
        },
    },
    {
        path: 'state-report-fields',
        loadComponent: () =>
            import('app/main/state-report/list/state-report-field.component').then(m => m.StateReportFieldComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcd86cd799439938', // navigation-ams.json ID
            fullPage: true,
        },
    },
    {
        path: 'state-reports',
        loadComponent: () =>
            import('app/main/state-report/generate/state-reports.component').then(m => m.StateReportsComponent),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcd86cd799439939', // navigation-ams.json ID
            fullPage: true,
        },
    },
    {
        path: 'student-program-setup',
        loadComponent: () =>
            import('app/main/student-program/setup/student-program-setup.component').then(
                m => m.StudentProgramSetupComponent
            ),
        canActivate: [UserRouteAccessService, AuthGuard],
        data: {
            permissionId: '507f1f77bcf86cd799439011', // navigation-ams.json ID
        },
    },
    {
        path: 'permission-error',
        loadComponent: () =>
            import('@ms/core/permission-error-page/permission-error-page.component').then(
                m => m.PermissionErrorComponent
            ),
    },
    // Not-Found-Page. This must be always end of the routers
    {
        path: 'page_not_found',
        loadComponent: () =>
            import('@ms/core/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/page_not_found',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //useHash: true, //, preloadingStrategy: PreloadSelectedModules,
            scrollPositionRestoration: 'top',
            scrollOffset: [0, 0],
            enableTracing: !environment.production,
            enableViewTransitions: false,
            //bindToComponentInputs: true,
            // Enable scrolling to anchors
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AppRoutingModule
    ) {
        throwIfAlreadyLoaded(parentModule, 'AppRoutingModule');
    }
}
